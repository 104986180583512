import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import "bootstrap/dist/css/bootstrap.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout"
import PostLink from "../components/post-link"

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <Layout>
      <SEO title="vikash chander" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
      </Helmet>
      <div id="projects">
        <h3 className="text-centre big-text box-underline">
          <FontAwesomeIcon
            icon={faProjectDiagram}
            color="black"
            className="heart mx-2"
          />
          Blogs
        </h3>
      </div>
      {Posts}
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
          }
        }
      }
    }
  }
`
