import React from "react"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css";
import "./post-link.styles.css"
import Divider from "@material-ui/core/Divider"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    margin:"1rem 0 1rem 0",
    // border: "5px solid rgb(111,41,97)",
    padding: "0.3rem",
    display: "flex",
    height: "11rem",
    width:"50rem",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      width:"auto"
    },
    borderRadius: "1rem",
  },
  subtitles: {
    margin: "0.2rem",
  },
  details: {
    margin: "0.5rem",
    display: "flex",
    minHeight: "15rem",
    flexDirection: "column",
  },
  content: {
    flex: "1 2 auto",
  },
  body: {
    margin: "1rem 0 2rem 0"
  },
}))

export default function PostLink({ post }) {
  const classes = useStyles()
  const theme = useTheme()

  return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography
            className={classes.header}
            gutterBottom
            variant="h5"
            component="h2"
          >
           <Link to={post.frontmatter.slug}>
      {post.frontmatter.title} 
      </Link>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          ({post.frontmatter.date})
          </Typography>
          <Divider />
          <Typography className={classes.body}>
          {post.frontmatter.description}
          </Typography>
        </CardContent>
      </Card>
  )
}
